'use client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useRouter } from 'navigation';
import { useTranslations } from 'next-intl';

const NotFoundPage: React.FC = () => {
  const t = useTranslations('NotFoundPage');
  const router = useRouter();

  const handleBack = () => {
    router.replace('/');
  };

  return (
    <Grid container columns={16} height="100%">
      <Grid
        size={{
          xs: 1,
          md: 3,
          lg: 4,
        }}
      />
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        size={{
          xs: 14,
          md: 10,
          lg: 8,
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h3" textAlign="center">
            {t('title')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }} width="100%">
            <Button variant="contained" onClick={handleBack}>
              {t('back-to-dashboard')}
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid
        size={{
          xs: 1,
          md: 3,
          lg: 4,
        }}
      />
    </Grid>
  );
};

export default NotFoundPage;
